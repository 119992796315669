import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import website from "../website/data.js";
import axios from "axios";
import "./PhoneSkins.css";

const PhoneSkins = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);
  return (
    <Layout>
      <div className="explore-products-container">
        <div className="popular-games">
          <div className="games-list">
            {products?.map((item, index) => (
              <div
                key={index}
                className="game"
                onClick={() => navigate(`/product/${item?.name}`)}
              >
                <img src={`${website?.link}/${item?.image}`} alt="pro-img" />
                {item?.hotTags && (
                  <span className="hottag">{item?.hotTags}</span>
                )}
                <h5>{item?.name}</h5>
                {item?.stock === "No" && (
                  <span className="outofstock">Out of stock</span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PhoneSkins;
