import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useSelector } from "react-redux";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import IMAGES from "../../img/image";
import StickyFooter from "./StickyFooter.js";
import "../Footer/Footer.css";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  return (
    <React.Fragment>
      <div className="container-fluid footer-container">
        <StickyFooter />
        <div className="wa-icon-cont">
          <Link target="_blank" to="https://wa.me/919862622104">
            <WhatsAppIcon className="icon" />
          </Link>
        </div>
        <div className="row">
          <div className="col-12 text-center mb-5">
            <div className="footer-logo">
              <span className="fs-1">
                <b>
                  CHIGONGLEI<span className="ffca00">STORE</span>
                </b>
              </span>
            </div>
            <span className="text-justify">
              <small>
                Welcome to Chigonglei Store, the trusted hub for all your
                in-game currency needs. Whether you're conquering battles in
                Mobile Legends, climbing the ranks in BGMI and PUBG, or
                exploring the breathtaking world of Genshin Impact, And Many
                More we’ve got you covered. Step into the world of limitless
                possibilities with Chigonglei Store, where exceptional service
                meets your gaming ambitions. Our dedicated team is always here
                to provide top-notch customer support, making your experience
                smooth and worry-free.
              </small>
            </span>
            {/* <span>Manipur , Bishnupur District, 795134</span> */}
          </div>
          <div className="px-lg-5 quick-links col-6">
            <h6>Quick Links</h6>
            <hr />
            <ul>
              {!user && (
                <>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/register">Register</Link>
                  </li>
                </>
              )}
              <li
                onClick={() => {
                  const phoneNumber = "919862622104";
                  const message = encodeURIComponent(
                    "Hello, I would like to inquire about your reseller services."
                  );
                  window.open(
                    `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`,
                    "_blank"
                  );
                }}
              >
                <Link>Reseller Program</Link>
              </li>
              <li>
                <Link to="/track-order">Track Order</Link>
              </li>
            </ul>
          </div>
          <div className="col-6">
            <h6>Important Pages</h6>
            <hr />
            <ul>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
            </ul>
          </div>
          <hr />
          <span className="text-start text-lg-center">
            <small>All Rights Reserved © 2024 | CHIGONGLEI STORE</small>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
