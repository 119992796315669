import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import SearchIcon from "@mui/icons-material/Search";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import SearchContainer from "../SearchContainer";
import "./StickyFooter.css";

const StickyFooter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState(false);

  return (
    <>
      <div className="sticky-footer d-flex d-lg-none">
        <div className="item" onClick={() => navigate("/")}>
          <HomeOutlinedIcon
            className={`icon ${location.pathname === "/" && "active"}`}
          />
          <span className={`${location.pathname === "/" && "active"}`}>
            Home
          </span>
        </div>
        <div className="item" onClick={() => navigate("/games")}>
          <SportsEsportsIcon
            className={`icon ${location.pathname === "/games" && "active"}`}
          />
          <span className={`${location.pathname === "/games" && "active"}`}>
            Games
          </span>
        </div>
        <div className="item" onClick={() => setSearch(!search)}>
          <SearchIcon
            className={`icon ${location.pathname === "/orders" && "active"}`}
          />
          <span className={`${location.pathname === "/orders" && "active"}`}>
            Search
          </span>
        </div>
        <div className="item" onClick={() => navigate("/orders")}>
          <ShoppingBagIcon
            className={`icon ${location.pathname === "/orders" && "active"}`}
          />
          <span className={`${location.pathname === "/orders" && "active"}`}>
            Orders
          </span>
        </div>
        <div className="item" onClick={() => navigate("/user-dashboard")}>
          <DashboardIcon
            className={`icon ${
              location.pathname === "/user-dashboard" && "active"
            }`}
          />
          <span
            className={`${location.pathname === "/user-dashboard" && "active"}`}
          >
            Dashboard
          </span>
        </div>
      </div>
      <SearchContainer search={search} setSearch={setSearch} />
    </>
  );
};

export default StickyFooter;
