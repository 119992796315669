import React, { useEffect, useState } from "react";
import { message, Pagination } from "antd";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import "./AdminQueries.css";

const AdminPayments = () => {
  const [allUser, setAllUser] = useState(null);
  const [originalUserData, setOriginalUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchEmail, setSearchEmail] = useState("");
  const [orderId, setOrderId] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedApi, setSelectedApi] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  function handleClearFilter() {
    setSearchEmail("");
    setSelectedApi("");
    setPaymentMode("");
    setOrderId("");
    setSelectedType("");
    setSelectedDate("");
    setSelectedMonth(new Date().getMonth() + 1);
    getAllPayments();
  }

  const getAllPayments = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/payment/get-all-payments", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAllUser(res.data.data);
        setOriginalUserData(res.data.data.reverse());
        setTotalCount(res.data.data.length);
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = () => {
    if (!originalUserData) return;

    const filteredUsers = originalUserData.filter((order) => {
      const emailMatch =
        !searchEmail ||
        order.email?.toLowerCase().includes(searchEmail.toLowerCase());
      const orderIdMatch =
        !orderId ||
        order.client_txn_id?.toLowerCase().includes(orderId.toLowerCase());
      const typeMatch = !selectedType || order.type === selectedType;
      const createdDate = order.createdAt ? new Date(order.createdAt) : null;

      // const dateMatch =
      //   !selectedDate ||
      //   (createdDate &&
      //     createdDate.toISOString().split("T")[0] ===
      //       new Date(selectedDate).toISOString().split("T")[0]);

      // const monthMatch =
      //   !selectedMonth ||
      //   (createdDate && createdDate.getMonth() + 1 === Number(selectedMonth));

      return emailMatch && orderIdMatch && typeMatch;
    });

    console.log(filteredUsers);
    setAllUser(filteredUsers);
    setTotalCount(filteredUsers.length);
  };

  useEffect(() => {
    getAllPayments();
  }, [selectedMonth]);

  useEffect(() => {
    handleSearch();
  }, [searchEmail, orderId, selectedType, selectedMonth, selectedDate]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current, size) => {
    setCurrentPage(1); // Reset to first page when changing page size
    setPageSize(size);
  };

  const displayedUsers = allUser?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const getStatusClass = (status) => {
    switch (status) {
      case "pending":
        return "text-warning";
      case "completed":
        return "text-success";
      case "cancelled":
        return "text-danger";
      case "refunded":
        return "text-primary";
      default:
        return "text-success";
    }
  };

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Orders</h3>
          <h6>Total Orders - {totalCount}</h6>
        </div>
        <hr />
        <div className="table-container">
          <div className="tools mb-5">
            <div className="form-fields">
              <input
                className="border"
                type="search"
                name="orderId"
                placeholder="Search by Order Id"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
              />
            </div>

            <div className="form-fields">
              <input
                className="border"
                type="search"
                name="email"
                placeholder="Search by Email"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
              />
            </div>

            <div className="form-fields">
              <select
                className="form-select"
                name="type"
                onChange={(e) => setSelectedType(e.target.value)}
              >
                <option value="">Select Type</option>
                <option value="wallet">wallet (addmoney)</option>
                <option value="order">order</option>
              </select>
            </div>

            {/* <div className="form-fields">
              <input
                type="date"
                name="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </div>

            <div className="form-fields">
              <select
                className="text-dark"
                name="month"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
              >
                <option value="">Select Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </div> */}
            <button
              className="bg-danger px-3"
              onClick={() => handleClearFilter()}
            >
              Clear Filter
            </button>
          </div>
          {loading ? (
            <div class="spinner-border text-dark" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            <>
              <table className="table user-table">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Date & Time</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedUsers &&
                    displayedUsers.map((user, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <small>{user?.client_txn_id}</small>
                          </td>
                          <td>
                            <small>{user?.email}</small>
                          </td>
                          <td>
                            <small>{user?.mobile}</small>
                          </td>
                          <td>
                            <small>₹{user?.amount}</small>
                          </td>
                          <td>
                            <small>{user?.type || "NO DATA"}</small>
                          </td>
                          <td>
                            <small className={getStatusClass(user?.status)}>
                              {user?.status}
                            </small>
                          </td>
                          <td>
                            <small>
                              {new Date(user?.createdAt).toLocaleString(
                                "default",
                                {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }
                              )}
                            </small>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <Pagination
                className="my-3"
                current={currentPage}
                pageSize={pageSize}
                total={totalCount}
                onChange={handlePageChange}
                showSizeChanger
                onShowSizeChange={handlePageSizeChange}
              />
            </>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminPayments;
