const IMAGES = {
  b1: require("../img/home/b1.png"),
  b2: require("../img/home/b2.png"),
  b3: require("../img/home/b3.png"),
  b4: require("../img/home/b4.png"),
  b5: require("../img/home/b5.png"),
  pgu: require("../img/products/pgu.jpg"),
  // socials
  facebook: require("../img/socials/facebook.png"),
  instagram: require("../img/socials/instagram.png"),
  whatsapp: require("../img/socials/logo.png"),
  telegram: require("../img/socials/telegram.png"),
  gmail: require("../img/socials/gmail.png"),
  youtube: require("../img/socials/youtube.png"),
  // payments
  upi: require("../img/payments/upi.jpg"),
  gpay: require("../img/payments/gpay.webp"),
  phonepe: require("../img/payments/phonepe.webp"),
  paytm: require("../img/payments/paytm.png"),
};

export default IMAGES;
