import React from "react";
import "./FollowUs.css";
import IMAGES from "../../img/image";
import { Link } from "react-router-dom";

const FollowUs = () => {
  return (
    <div className="followus-container">
      <span>
        <small>Lastest News & Support</small>
      </span>
      <h2>Follow Us At</h2>
      <div className="socials">
        <Link target="_blank" to="https://www.facebook.com/chigonglei2.0play">
          <img src={IMAGES.facebook} alt="" />
        </Link>

        <Link target="_blank" to="https://www.instagram.com/chigongleistore">
          <img src={IMAGES.instagram} alt="" />
        </Link>

        <Link target="_blank" to="https://wa.me/919862622104">
          <img src={IMAGES.whatsapp} alt="" />
        </Link>

        <Link target="_blank" to="https://youtube.com/@sanjoykhoirom3929">
          <img src={IMAGES.youtube} alt="" />
        </Link>
      </div>
    </div>
  );
};

export default FollowUs;
