import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import Layout from "../components/Layout/Layout";
import getUserData from "../utils/userDataService.js";
import axios from "axios";
// ICON
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import HelpIcon from "@mui/icons-material/Help";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// CSS
import "./ProductInfo.css";
import IMAGES from "../img/image";
import website from "../website/data";
import { setUser } from "../redux/features/userSlice.js";

const ProductInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [product, setProduct] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userCheckLoader, setUserCheckLoader] = useState(false);
  //!NEW STATE
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  //! API BASED
  const [orderId, setOrderId] = useState(false);
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [tagThree, setTagThree] = useState("");
  const [notification, setNotification] = useState(false);
  const [popup, setPopup] = useState(false);
  const [userIdImage, setUserIdImage] = useState(false);
  const [mode, setMode] = useState("UPI");
  const [balance, setBalance] = useState("");
  const [loader, setLoader] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);

  const couponRef = useRef(null);

  const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
      // eslint-disable-next-line
    }, [orderSuccess]);
    return null;
  };
  ScrollToTop();

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
    setOrderId(orderId);
  };

  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        if (user?.reseller === "yes") {
          const defaultAmount = res.data.data?.cost?.[0]?.amount;
          const defaultPrice = res.data.data?.cost?.[0]?.resPrice;
          const defaultId = res.data.data?.cost?.[0]?.id;
          setAmount(defaultAmount);
          setSelectedPrice(defaultPrice);
          setProductId(defaultId);
        } else {
          const defaultAmount = res.data.data?.cost?.[0]?.amount;
          const defaultPrice = res.data.data?.cost?.[0]?.price;
          const defaultId = res.data.data?.cost?.[0]?.id;
          setAmount(defaultAmount);
          setSelectedPrice(defaultPrice);
          setProductId(defaultId);
        }
        setProduct(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct();
    generateOrderId();
  }, []);

  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
      setSelectedPrice(price);
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
      setSelectedPrice(price);
    }
  }

  // check player
  async function handleCheckPlayer() {
    if (userId === "" || zoneId === "") {
      return message.error(
        `${userId === "" ? "Enter user id" : "Enter zone id"}`
      );
    }
    try {
      setUserCheckLoader(true);
      const idPro = productId.split("&")[0];
      const object = {
        userid: userId,
        zoneid: zoneId,
        apiName: product?.apiName,
      };
      const res = await axios.post("/api/payment/get-role", object);
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setUserCheckLoader(false);
      } else {
        message.error(res.data.message);
        setUserCheckLoader(false);
      }
    } catch (error) {
      console.log(error);
      setUserCheckLoader(false);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setNotification(true);
    }, 2000);
  }, []);

  function checkPlaceOrder(e) {
    if (product?.fields === "5") {
      if (userId === "") {
        return message.error(`Enter your ${product?.tagOne}`);
      }
      if (zoneId === "") {
        return message.error(`Enter your ${product?.tagTwo}`);
      }
      if (tagThree === "") {
        return message.error(`Select Fields is Empty`);
      }
    }
    if (product?.fields === "4") {
      if (userId === "") {
        return message.error(`Enter your ${product?.tagOne}`);
      }
      if (zoneId === "") {
        return message.error(`Select Fields is Empty`);
      }
    }
    if (product?.fields === "3" || product?.fields === "5") {
      if (userId === "") {
        return message.error(`Enter your ${product?.tagOne}`);
      }
      if (zoneId === "") {
        return message.error(`Enter your ${product?.tagTwo}`);
      }
      if (tagThree === "") {
        return message.error(`Enter your ${product?.tagThree}`);
      }
    }
    if (product?.fields === "2") {
      if (userId === "") {
        return message.error(`Enter your ${product?.tagOne}`);
      }
      if (zoneId === "") {
        return message.error(`Enter your ${product?.tagTwo}`);
      }
    }
    if (product?.fields === "1") {
      if (userId === "") {
        return message.error(`Enter your ${product?.tagOne}`);
      }
    }
    if (product?.playerCheckBtn === "yes" && !playerCheck) {
      return message.error("Check your username to continue");
    }

    if (product?.api === "yes") {
      if (product?.apiName === "yokcash") {
        if (mode === "UPI") {
          handleYokcashUpiOrder(e);
        } else {
          handleYokcashWalletOrder(e);
        }
      } else {
        if (mode === "UPI") {
          handleSmileOneUpiOrder(e);
        } else {
          handleSmileOneWalletOrder(e);
        }
      }
    } else {
      if (mode === "UPI") {
        handleUpiOrder(e);
      } else {
        handleWalletOrder(e);
      }
    }
  }

  // yokcash
  async function handleYokcashUpiOrder(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://chigongleistore.com/api/yokcash/status?orderId=${orderId}`,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      const response = await axios.post("/api/yokcash/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function handleYokcashWalletOrder(e) {
    if (parseFloat(balance) < parseFloat(selectedPrice)) {
      return message.error("Your balance is less for this order");
    }
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      setLoading(true);
      const res = await axios.post("/api/yokcash/wallet", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setOrderSuccess(true);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  // smile
  const handleSmileOneUpiOrder = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.region,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://chigongleistore.com/api/smile/status?orderId=${orderId}`,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      const response = await axios.post("/api/smile/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    e.preventDefault();
    if (parseFloat(balance) < parseFloat(selectedPrice)) {
      return message.error("Your balance is less for this order");
    }
    try {
      const orderObject = {
        orderId: orderId,
        userid: userId,
        zoneid: zoneId,
        productid: productId,
        region: product?.region,
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
      };
      setLoading(true);
      const res = await axios.post("/api/smile/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setOrderSuccess(true);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // manual
  const handleUpiOrder = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
        customer_email: user?.email,
        callback_url: `https://chigongleistore.com/api/manual/status?orderId=${orderId}`,
        txn_note: userId + "@" + zoneId + "@" + tagThree + "@" + amount,
      };

      const response = await axios.post("/api/manual/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleWalletOrder = async (e) => {
    e.preventDefault();
    if (parseFloat(balance) < parseFloat(selectedPrice)) {
      return message.error("Your balance is less for this order");
    }
    try {
      setLoading(true);
      const orderObject = {
        api: "no",
        orderId: orderId,
        userid: userId,
        zoneid: zoneId,
        tagThree: tagThree,
        productid: productId,
        region: product?.region,
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
      };

      const res = await axios.post("/api/manual/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setOrderSuccess(true);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleAmountClick = (itemAmount) => {
    setAmount(itemAmount);
    setPriceAndId(itemAmount);
    couponRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Layout>
      {orderSuccess ? (
        <div className="order-success-container">
          <div className="text-center">
            <CheckCircleIcon className="icon" />
            <h4>Order Sucessful</h4>
          </div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <td>Order Id</td>
                <td>{orderId}</td>
              </tr>
              <tr>
                <td>Product Details</td>
                <td>{product?.name}</td>
              </tr>
              <tr>
                <td>Pack Details</td>
                <td>{amount}</td>
              </tr>
              <tr>
                <td>User Id</td>
                <td>{userId}</td>
              </tr>
              {zoneId !== "" && (
                <tr>
                  <td>Zone Id</td>
                  <td>{zoneId}</td>
                </tr>
              )}
              <tr>
                <td>Payment Method</td>
                <td>Wallet</td>
              </tr>
            </thead>
          </table>
          <div className="btnss">
            <button onClick={() => navigate("/orders")}>Order History</button>
            <button onClick={() => navigate("/")}>Back to Shopping</button>
          </div>
        </div>
      ) : (
        <div className="product-info-container">
          {/* NOTIFICATION  */}
          {notification &&
            product?.notificationHeading?.split("=")[1] === "YES" && (
              <div className="notification-modal">
                <div className="notification">
                  <h4>{product?.notificationHeading?.split("=")[0]}</h4>
                  <p>{product?.notificationDescOne}</p>
                  <p>{product?.notificationDescTwo}</p>
                  <button onClick={() => setNotification(false)}>Close</button>
                </div>
              </div>
            )}

          <div className="product-info-content mb-2">
            {/* BANNER IMAGE  */}
            {/* BANNER IMAGE  */}
            {/* BANNER IMAGE  */}
            <div
              className={`p-info-container area loading ${loading && "active"}`}
              style={{
                backgroundImage: `url(${
                  website.link
                }/${product?.bannerImage?.replace(/\\/g, "/")})`,
                backgroundSize: "cover",
                backgroundPosition: "top",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="pro-img">
                {loading ? (
                  <div className="imagess"></div>
                ) : (
                  <img src={`${website.link}/${product?.image}`} alt="" />
                )}
                <div>
                  <h2
                    className={`m-0 loading ${loading && "active"}`}
                    style={{ overflow: "hidden" }}
                  >
                    {product?.name}
                  </h2>
                  <h6 className="m-0 d-none d-md-none d-lg-block">
                    {product?.desc}
                  </h6>
                  <div className="features">
                    <button className={`loading ${loading && "active"}`}>
                      <SupportAgentIcon className="icon" />
                      24/7 Chat Support
                    </button>
                    <button className={`loading ${loading && "active"}`}>
                      <AssuredWorkloadIcon className="icon" />
                      Safe Payment
                    </button>
                    <button className={`loading ${loading && "active"}`}>
                      <VerifiedUserIcon className="icon" />
                      Official Store
                    </button>
                    <button className={`loading ${loading && "active"}`}>
                      <WorkspacePremiumIcon className="icon" />
                      Service Guarantee
                    </button>
                    <button className={`loading ${loading && "active"}`}>
                      <ElectricBoltIcon className="icon" />
                      Instant Delivery
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* INS IMAGE */}
            {/* INS IMAGE */}
            {/* INS IMAGE */}
            {product?.instructionImage && (
              <div className="instruction-image">
                <button onClick={() => setPopup(!popup)}>
                  How to Purchase
                  <InfoIcon className="icon me-1" />
                </button>
                <div className={`ins-popup ${popup && "active"}`}>
                  <img
                    src={`${website.link}/${product?.instructionImage}`}
                    alt=""
                  />
                  <CancelIcon
                    className="icon close-icon"
                    onClick={() => setPopup(!popup)}
                  />
                </div>
              </div>
            )}

            <div className="amount-and-fields">
              {/* AMOUNT PACK  */}
              {/* AMOUNT PACK  */}
              {/* AMOUNT PACK  */}
              <div className="p-amount bgpadding">
                {product?.cost?.map((item, index) => {
                  return (
                    <div
                      onClick={() => handleAmountClick(item.amount)}
                      key={index}
                      className={`amount ${
                        amount === item?.amount && "active"
                      }`}
                    >
                      <div className="up">
                        <div className="image">
                          <img src={item?.pimg} alt="" />
                        </div>
                        <div className="pack">
                          <p>{item?.amount}</p>
                          <p>{item?.packData}</p>
                        </div>
                      </div>
                      <div className="down">
                        <p>
                          ₹
                          {user?.reseller === "yes"
                            ? item?.resPrice
                            : item?.price}
                        </p>
                        <p className="text-danger">
                          {item?.fakePrice && "₹" + item?.fakePrice}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="info-user-fields" ref={couponRef}>
                {/* USERID IMAGE  */}
                {/* USERID IMAGE  */}
                {/* USERID IMAGE  */}
                <div className="d-flex">
                  <label htmlFor="">Order Information</label>
                  <HelpIcon
                    className="icon"
                    onClick={() => setUserIdImage(!userIdImage)}
                  />
                  {userIdImage && (
                    <div
                      className="helpimg"
                      onClick={() => setUserIdImage(!userIdImage)}
                    >
                      <CancelIcon
                        className="icon"
                        onClick={() => setUserIdImage(!userIdImage)}
                      />
                      <img src={product?.userIdImage} alt="" />
                    </div>
                  )}
                </div>

                {/* FIELDS */}
                {/* FIELDS */}
                {/* FIELDS */}
                <div className="user-input-fields bgpadding">
                  {product?.fields === "1" ? (
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder={product?.tagOne}
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  ) : product?.fields === "2" ? (
                    <>
                      <div className="form-fields">
                        <input
                          className="player-tag"
                          type="number"
                          name="userId"
                          placeholder={product?.tagOne}
                          onChange={(e) => setUserId(e.target.value)}
                          value={userId}
                        />
                      </div>
                      <div className="form-fields">
                        <input
                          className="player-tag"
                          type="number"
                          name="zoneId"
                          placeholder={product?.tagTwo}
                          onChange={(e) => setZoneId(e.target.value)}
                          value={zoneId}
                        />
                      </div>
                    </>
                  ) : product?.fields === "3" ? (
                    <>
                      <div className="form-fields">
                        <input
                          className="player-tag"
                          type="text"
                          name="userId"
                          placeholder={product?.tagOne}
                          onChange={(e) => setUserId(e.target.value)}
                          value={userId}
                        />
                      </div>
                      <div className="form-fields">
                        <input
                          className="player-tag"
                          type="text"
                          name="zoneId"
                          placeholder={product?.tagTwo}
                          onChange={(e) => setZoneId(e.target.value)}
                          value={zoneId}
                        />
                      </div>
                      <div className="form-fields">
                        <input
                          className="player-tag"
                          type="text"
                          name="tagThree"
                          placeholder={product?.tagThree}
                          onChange={(e) => setTagThree(e.target.value)}
                          value={tagThree}
                        />
                      </div>
                    </>
                  ) : product?.fields === "4" ? (
                    <>
                      <div className="form-fields">
                        <input
                          className="player-tag"
                          type="text"
                          name="userId"
                          placeholder={product?.tagOne}
                          onChange={(e) => setUserId(e.target.value)}
                          value={userId}
                        />
                      </div>
                      <div className="form-fields">
                        <select
                          name="zoneId"
                          className="form-select player-tag"
                          onChange={(e) => setZoneId(e.target.value)}
                        >
                          <option value="">Select Server</option>
                          {product?.tagFour?.split("+")?.map((item, index) => {
                            return <option value={item}>{item}</option>;
                          })}
                        </select>
                      </div>
                    </>
                  ) : (
                    product?.fields === "5" && (
                      <>
                        <div className="form-fields">
                          <input
                            className="player-tag"
                            type="text"
                            name="userId"
                            placeholder={product?.tagOne}
                            onChange={(e) => setUserId(e.target.value)}
                            value={userId}
                          />
                        </div>
                        <div className="form-fields">
                          <input
                            className="player-tag"
                            type="text"
                            name="zoneId"
                            placeholder={product?.tagTwo}
                            onChange={(e) => setZoneId(e.target.value)}
                            value={zoneId}
                          />
                        </div>
                        <div className="form-fields">
                          <select
                            name="tagThree"
                            className="form-select player-tag"
                            onChange={(e) => setTagThree(e.target.value)}
                          >
                            <option value="">Select Server</option>
                            {product?.tagFour
                              ?.split("+")
                              ?.map((item, index) => {
                                return <option value={item}>{item}</option>;
                              })}
                          </select>
                        </div>
                      </>
                    )
                  )}

                  {playerCheck && (
                    <span className="text-success">
                      Username: {playerCheck}
                    </span>
                  )}

                  {product?.playerCheckBtn === "yes" && (
                    <button
                      onClick={handleCheckPlayer}
                      className="add-to-cart-btn"
                    >
                      Validate Username
                      {userCheckLoader && (
                        <div
                          class="spinner-grow spinner-grow-sm ms-1"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  )}
                </div>

                {/* PAYMENT */}
                {/* PAYMENT */}
                {/* PAYMENT */}
                <div className="mt-4 bgpadding">
                  <div className="title">
                    <span>Select Payment Mode</span>
                  </div>
                  <div className="payment">
                    <div
                      onClick={() => setMode("UPI")}
                      className={`upi ${mode === "UPI" && "active"}`}
                    >
                      <div className="pimages">
                        <img src={IMAGES.upi} alt="" />
                        <img src={IMAGES.gpay} alt="" />
                        <img src={IMAGES.paytm} alt="" />
                        <img src={IMAGES.phonepe} alt="" />
                      </div>
                      <h4 className="m-0">UPI</h4>
                    </div>
                    <div
                      onClick={() => setMode("WALLET")}
                      className={`wallet ${mode === "WALLET" && "active"}`}
                    >
                      <div>
                        <AccountBalanceWalletIcon className="icon" />
                        <span className="ms-2">
                          {user && <b>Available Balance: {balance}</b>}
                        </span>
                      </div>
                      <h4 className="m-0">Wallet</h4>
                    </div>
                  </div>
                </div>

                <div className="bgpadding mt-4">
                  <div className="totalamount-paymentmode">
                    <span>
                      <small>Total Amount</small>
                    </span>
                    <span>
                      <small>
                        {amount} | ₹{selectedPrice}
                      </small>
                    </span>
                  </div>
                  {!user ? (
                    <button
                      className="add-to-cart-btn"
                      onClick={() => navigate("/login")}
                    >
                      Please login to continue
                    </button>
                  ) : product?.stock === "no" ? (
                    <button
                      className="add-to-cart-btn"
                      style={{ opacity: "0.7" }}
                    >
                      Out of Stock
                    </button>
                  ) : (
                    <button
                      disabled={loading}
                      onClick={checkPlaceOrder}
                      className="add-to-cart-btn"
                    >
                      Buy Now
                      {loading && (
                        <div
                          class="spinner-grow spinner-grow-sm ms-2"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  )}
                </div>

                <div className="safe-checkout col-12 col-lg-6 mt-4">
                  <span>Guaranteed Safe Checkout</span>
                  <img className="safe-checkout-img" src={IMAGES.pgu} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!orderSuccess && (
        <div className="product-desc">
          <h5>Description</h5>
          <p>{product?.desc}</p>
        </div>
      )}
    </Layout>
  );
};

export default ProductInfo;
